import { OrderHistoryOrder, OrderHistoryPage, OrderSortFieldType } from '../../../../../../generated/graphql';

export type MyAccountOrdersProps = {
  data: OrderHistoryPage;
  viewBy?: string;
};

export type State = {
  showList?: boolean;
  loadingOrders?: boolean;
  orders?: OrderHistoryOrder[];
  sortMode?: { field: OrderSortFieldType; ascending: boolean };
};

export enum OrderActionType {
  SetOrderDisplayType = 'SetOrderDisplayType',
  SetOrders = 'SetOrders',
  SetLoadingOrders = 'SetLoadingOrders',
}

export type Action =
  | { type: OrderActionType.SetOrderDisplayType; isList: boolean }
  | { type: OrderActionType.SetOrders; orders?: OrderHistoryOrder[]; sortMode?: { field: OrderSortFieldType; ascending: boolean } }
  | { type: OrderActionType.SetLoadingOrders; loading: boolean };

import React, { FunctionComponent, useContext, useReducer } from 'react';
import { Button, Icon, Loader } from 'rs-emd-ui-atoms';
import { OrderList, OrderProductList } from '../..';
import { QuickOrderContext } from '../../../../../../components/app/quick-order-context';
import {
  OrderHistoryOrder,
  OrderHistoryPageDocument,
  OrderHistoryPageQuery,
  OrderHistoryPageQueryVariables,
  SortDirection,
} from '../../../../../../generated/graphql';
import { graphQLService } from '../../../../../../services/graphql-service';
import { getLabel } from '../../../../helpers/html.utils';
import { Pagination } from '../../../product-list-page';
import { MetaDataAndTagging } from '../../../shared';
import sharedStyles from '../my-account-shared.module.scss';
import { Action, MyAccountOrdersProps, OrderActionType, State } from './my-account-orders-component.model';
import styles from './my-account-orders-component.module.scss';
import { OrdersHeaderArea } from './orders-header-area/orders-header-area-component';

export const MyAccountOrders: FunctionComponent<MyAccountOrdersProps> = (props: MyAccountOrdersProps) => {
  const [state, dispatch] = useReducer(reducer, {
    orders: props.data?.orderHistoryArea?.orders,
    showList: (props.viewBy ?? 'products') === 'list',
  });
  const { setQuickOrderProps } = useContext(QuickOrderContext);

  function getPageInfo(top: boolean) {
    return (
      <p
        className={`${styles['page-info']} ${top ? styles.top : styles.bottom}`}
        dangerouslySetInnerHTML={{
          __html: getLabel(props.data.labels, 'results_counter')
            .replace('[page]', `<b>${props.data.orderHistoryArea?.noOfOrders}</b>`)
            .replace('[total]', `<b>${props.data.orderHistoryArea?.noOfOrders}</b>`),
        }}></p>
    );
  }

  function getNoOrders() {
    return (
      <>
        <div className={sharedStyles.empty}>
          <Icon name='basket' width={60} height={60}></Icon>
          <div className={sharedStyles.details}>
            <p>{getLabel(props.data.labels, 'no_orders_header')}</p>
            <p>{getLabel(props.data.labels, 'no_orders_details')}</p>
          </div>
        </div>
        <div className={sharedStyles.buttons}>
          <Button buttonType='tertiary' href='/' isExternalLink={false} text={getLabel(props.data.labels, 'shop_now')}></Button>
          <Button
            buttonType='tertiary'
            text={getLabel(props.data.labels, 'quick_order')}
            onClick={() => {
              setQuickOrderProps({
                topicIdentifier: '', //props.topicIdentifier,
                openOnAddByStockNum: true,
                isVisible: true,
              });
            }}></Button>
        </div>
      </>
    );
  }

  return (
    <div className={`inner-page-wrapper ${styles['my-account-orders-container']}`}>
      {/* Head data */}
      <MetaDataAndTagging tagging={props.data.tagging} />

      {props.data.orderHistoryArea?.noOfOrders ? (
        <>
          <OrdersHeaderArea
            labels={props.data.labels}
            defaultValue={state.showList ? 'list' : 'products'}
            changeOrderTypeCallback={(isList) => dispatch({ type: OrderActionType.SetOrderDisplayType, isList: isList })}
          />
          {state.loadingOrders ? (
            <div className={styles['loader-container']}>
              <Loader />
            </div>
          ) : (
            <>
              {getPageInfo(true)}

              {state.showList ? (
                <OrderList
                  labels={props.data.labels}
                  orders={state.orders}
                  showSortArrows
                  sortMode={state.sortMode}
                  onSortCallback={(field, ascending) => {
                    dispatch({ type: OrderActionType.SetLoadingOrders, loading: true });
                    graphQLService.apolloClient
                      .query<OrderHistoryPageQuery, OrderHistoryPageQueryVariables>({
                        query: OrderHistoryPageDocument,
                        variables: {
                          sort: {
                            sortField: field,
                            direction: ascending ? SortDirection.Ascending : SortDirection.Descending,
                          },
                        },
                        fetchPolicy: 'network-only',
                      })
                      .then((res) => {
                        dispatch({ type: OrderActionType.SetLoadingOrders, loading: false });
                        if (res.data) {
                          dispatch({
                            type: OrderActionType.SetOrders,
                            orders: res.data.orderHistoryPage.orderHistoryArea?.orders as OrderHistoryOrder[],
                            sortMode: { field, ascending },
                          });
                        }
                      });
                  }}
                />
              ) : (
                <>
                  {state.orders?.map((order) => (
                    <OrderProductList labels={props.data.labels} data={order} />
                  ))}
                </>
              )}
              {getPageInfo(false)}
              <Pagination
                labels={props.data.labels}
                pagination={{ numberOfPages: 5, size: 20, totalProducts: 100, offset: 1 }}
                onPageSizeChange={() => {}}
                className={styles.pagination}
                alwaysShowPageSizeControls
              />
            </>
          )}
        </>
      ) : (
        <>{getNoOrders()}</>
      )}
    </div>
  );

  function reducer(state: State, action: Action): State {
    switch (action.type) {
      case OrderActionType.SetOrderDisplayType:
        return {
          ...state,
          showList: action.isList,
        };
      case OrderActionType.SetOrders:
        return {
          ...state,
          orders: action.orders,
          sortMode: action.sortMode,
        };
      case OrderActionType.SetLoadingOrders:
        return {
          ...state,
          loadingOrders: action.loading,
        };
    }
  }
};
